import { LANG_LIST, DEFAULT_LANG } from 'constants/translations';

export const getLocaleInPath = (path, fallback = '') => {
  const localPath = path.match(/\/([a-z]+)/g);
  return localPath ? localPath[0].replace(/\//, '') : fallback;
};

export const getLocale = (path) => {
  const localPath = path.match(/\/([a-z]+)/g);
  const locale = localPath ? localPath[0].replace(/\//, '') : '';

  return LANG_LIST.includes(locale) ? locale : DEFAULT_LANG;
};

export const getPathWithoutLocale = (pathname) => {
  const validLocales = LANG_LIST.map((l) => `/${l}`);
  const regex = new RegExp(`(${validLocales.join('|')})(/|$)`);
  const locale = pathname.match(regex);
  if (locale) return pathname.replace(locale[1], '');
  return pathname;
};
