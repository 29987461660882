import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';

import { getLocaleInPath, getLocale } from 'helpers/path';
import { LANG_LIST, DEFAULT_LANG } from 'constants/translations';
import { PATH_STREAM, PATH_INFO, PATH_COOKIE } from 'constants/paths';

const HomePage = lazy(async () => import('scenes/home/home'));
const StreamPage = lazy(async () => import('scenes/stream/stream'));
const InfoPage = lazy(async () => import('scenes/info/info'));
const CookiePage = lazy(async () => import('scenes/cookie/cookie'));
const NotFound = lazy(async () => import('scenes/notFound/notFound'));

const AppRoutes = () => {
  const base = `/:locale(${LANG_LIST.join('|')})?`;
  const pathLocale = getLocaleInPath(window.location.pathname);

  let redirectPath = '';
  if (!LANG_LIST.includes(pathLocale)) {
    redirectPath = `/${DEFAULT_LANG}${window.location.pathname}`;
  }

  const redirectLocalePath = getLocale(window.location.pathname)
  
  return (
    <Suspense fallback={<div className='fasdsada' />}>
      <Switch>
        {redirectPath && <Redirect to={redirectPath} />}
        <Route path={base} exact component={HomePage} />
        <Route exact path={`${base}/musiikkimedia-testi`}>
          <Redirect to={`/${redirectLocalePath}/${PATH_STREAM}/77544`} />
        </Route>
        <Route exact path={`${base}/musiikkimedia`}>
          <Redirect to={`/${redirectLocalePath}/${PATH_STREAM}/77583`} />
        </Route>
        <Route
          path={`${base}/${PATH_STREAM}/:eventId`}
          exact
          component={StreamPage}
        />
        <Route path={`${base}/${PATH_INFO}`} exact component={InfoPage} />
        <Route path={`${base}/${PATH_COOKIE}`} exact component={CookiePage} />
        <Route component={NotFound} />
      </Switch>
    </Suspense>
  );
};

export default withRouter(AppRoutes);
