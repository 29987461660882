import tiketti from './baseApi';

import { isUndefined } from 'lodash';

import { DEFAULT_LANG } from 'constants/translations';
import {
  CTYPE_COMP_GROUP,
  CTYPE_FOOTER_LINK,
  CTYPE_PAGE
} from 'constants/contentful';

import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

const defaults = {
  url: process.env.REACT_APP_CONTENTFUL_SOURCE
};

const contentTypes = [CTYPE_COMP_GROUP, CTYPE_FOOTER_LINK, CTYPE_PAGE];

const findFieldFromIncludes = (includes, id) => {
  return includes.Entry.find((item) => item.sys.id === id);
};

export const retrieveTextContent = async (locale = DEFAULT_LANG) => {
  try {
    const url = defaults.url;
    const params = {
      locale: locale,
      include: 4,
      'sys.contentType.sys.id[in]': contentTypes.join(',')
    };
    const contentRequest = await tiketti.get(url, params);
    const textContent = {};
    if (contentRequest.status === 200) {
      const data = contentRequest.data;

      data.items.forEach((item) => {
        const contentType = item.sys.contentType.sys.id;

        if (!Object.keys(textContent).includes(contentType)) {
          textContent[contentType] = {};
        }

        if (contentType === CTYPE_COMP_GROUP) {
          textContent[contentType][item.fields.key] = {};

          item.fields.elements.forEach((element) => {
            const field = findFieldFromIncludes(data.includes, element.sys.id);

            const value = field.fields.value ?? field.fields.valueExtra;
            textContent[contentType][item.fields.key][field.fields.key] = value;
          });
        } else if (contentType === CTYPE_FOOTER_LINK) {
          item.fields.menuItems.forEach((elem) => {
            if (isUndefined(textContent[contentType].menuItems)) {
              textContent[contentType].menuItems = [];
            }
            const fieldData = findFieldFromIncludes(data.includes, elem.sys.id);

            textContent[contentType].menuItems.push({
              text: fieldData.fields.text,
              linkAddress: fieldData.fields.address
            });
          });
        } else if (contentType === CTYPE_PAGE) {
          const page = {
            mainLabel: item.fields.mainLabel,
            menuItems: []
          };

          item.fields.menuItem.forEach((elem) => {
            const fieldData = findFieldFromIncludes(data.includes, elem.sys.id);

            page.menuItems.push({
              label: fieldData.fields.itemLabel,
              content: documentToHtmlString(fieldData.fields.content)
            });
          });

          textContent[contentType][item.fields.key] = { ...page };
        }
        return item;
      });
    }

    return textContent;
  } catch (error) {
    return false;
  }
};
