export const LANG_FI = 'fi';
export const LANG_EN = 'en';

export const LANG_LIST = [LANG_FI, LANG_EN];
export const LANG_KEY_VALUE = {
  [LANG_FI]: 'Suomeksi',
  [LANG_EN]: 'English'
};
export const DEFAULT_LANG = LANG_FI;

export const translations = {
  fi: {
    elements: {
      input: {
        searchPlaceholder: 'etsi strimmi',
        emailPlaceholder: 'Sähköpostiosoite'
      },
      button: {
        emailSend: 'Tilaa',
        buyTicket: 'Osta Lippu',
        acceptCookie: 'HYVÄKSYN KAIKKI EVÄSTEET',
        acceptSelectedCookie: 'Tallenna valinnat',
        cookieSettings: 'Evästeasetukset'
      }
    },
    codeBox: {
      enterCode: 'Enter Code',
      errorGeneric: 'Unable to process code'
    },
    infoPage: {
      tags: {
        firstTag: 'striimin katsojalle',
        secondTag: 'striimin järjestäjälle'
      },
      content: {
        viewers: [
          {
            key: 0,
            title: 'vLorem ipsum dolor sit amet, consectetuer adipiscing elit',
            description: `Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam 
              ullamcorper nonummy nibh euismod tincidunt ut laoreet dolore 
              magna aliquam erat volutpat.`
          },
          {
            key: 1,
            title: 'vTation ullamcorper suscipit',
            description: `Lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel 
              eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla 
              facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit.`
          },
          {
            key: 2,
            title:
              'vAugue duis dolore te feugait nulla facilisi. Lorem ipsum dolor sit amet',
            description: `Cons ectetuer adipiscing elit, sed diam nonummy nibh euismod 
              tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim 
              ad minim veniam, quis nostrud exerci tation ullamcor- per suscipit lobortis nisl ut aliquip ex ea commodo consequat.`
          },
          {
            key: 3,
            title: 'vLorem ipsum dolor sit amet',
            description: `Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tinci- dunt ut 
              laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper 
              suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, 
              vel illum dolore eu feu- giat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. 
              Lorem ipsum dolor sit amet, cons ectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. 
              Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tinci- dunt ut laoreet dolore magna aliquam erat volutpat.`
          },
          {
            key: 4,
            title: 'vTation ullamcorper suscipit',
            description: `Lobortis nisl ut aliquip ex ea commodo consequat. 
              Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, 
              vel illum dolore eu feugiat nulla facilisis 
              at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit.`
          },
          {
            key: 5,
            title:
              'vAugue duis dolore te feugait nulla facilisi. Lorem ipsum dolor sit amet',
            description: `Cons ectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt 
              ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, 
              quis nostrud exerci tation ullamcor- per suscipit lobortis nisl ut aliquip ex ea commodo consequat.`
          },
          {
            key: 6,
            title: 'vUt wisi enim ad minim veniam',
            description: `Quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. 
              Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, 
              vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit prae- sent luptatum zzril 
              delenit augue duis dolore te feugait nulla facilisi.
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tinci- dunt ut 
              laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci.`
          }
        ],
        organizers: [
          {
            key: 0,
            title: 'orTation ullamcorper suscipit',
            description: `Lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel 
              eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla 
              facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit.`
          },
          {
            key: 1,
            title: 'orLorem ipsum dolor sit amet, consectetuer adipiscing elit',
            description: `Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam 
              ullamcorper nonummy nibh euismod tincidunt ut laoreet dolore 
              magna aliquam erat volutpat.`
          },
          {
            key: 2,
            title:
              'orAugue duis dolore te feugait nulla facilisi. Lorem ipsum dolor sit amet',
            description: `Cons ectetuer adipiscing elit, sed diam nonummy nibh euismod 
              tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim 
              ad minim veniam, quis nostrud exerci tation ullamcor- per suscipit lobortis nisl ut aliquip ex ea commodo consequat.`
          },
          {
            key: 3,
            title: 'orTation ullamcorper suscipit',
            description: `Lobortis nisl ut aliquip ex ea commodo consequat. 
              Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, 
              vel illum dolore eu feugiat nulla facilisis 
              at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit.`
          },
          {
            key: 4,
            title:
              'orAugue duis dolore te feugait nulla facilisi. Lorem ipsum dolor sit amet',
            description: `Cons ectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt 
              ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, 
              quis nostrud exerci tation ullamcor- per suscipit lobortis nisl ut aliquip ex ea commodo consequat.`
          },
          {
            key: 5,
            title: 'orLorem ipsum dolor sit amet',
            description: `Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tinci- dunt ut 
              laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper 
              suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, 
              vel illum dolore eu feu- giat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. 
              Lorem ipsum dolor sit amet, cons ectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. 
              Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tinci- dunt ut laoreet dolore magna aliquam erat volutpat.`
          },
          {
            key: 6,
            title: 'orUt wisi enim ad minim veniam',
            description: `Quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. 
              Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, 
              vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit prae- sent luptatum zzril 
              delenit augue duis dolore te feugait nulla facilisi.
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tinci- dunt ut 
              laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci.`
          }
        ]
      }
    },
    hero: {
      title: 'tiketti striimi',
      description:
        'Mollit est est amet Lorem. Ipsum aliqua aute consequat adipisicing. Anim dolore exercitation adipisicing sunt Lorem proident. Deserunt Lorem duis esse ut reprehenderit eiusmod do.'
    },
    newsLetter: {
      title: 'Tilaa uutiskirje',
      description:
        'Non laboris ex do exercitation. Aliqua eiusmod in labore sit do nulla ex quis fugiat. Do id ex esse est fugiat qui elit dolore fugiat consequat voluptate excepteur.'
    },
    streams: {
      title: 'Tallenteet'
    },
    footer: {
      relatedLinks: {
        privacyPolicy: 'Tietosuoja',
        cookies: 'evästeet'
      }
    },
    cookie: {
      title: 'Tietoa evästeistä',
      description: `Tiketti Gallerian verkkokauppa käyttää erilaisia evästeitä sekä
      sivuston toimivuuden ja mukavan käyttökokemuksen takaamiseksi että
      markkinointiin ja tiedonkeruuseen. Sivuston käyttö edellyttää
      vähintään toiminnallisuuden kannalta välttämättömien evästeiden
      hyväksymistä.`,
      buttonLabel: 'Hyväksy kaikki evästeen tai muuta evästeasetuksiasi.',
      readAboutCookie: 'Lue lisää käyttämistämme evästeistä',
      settings: {
        title: 'Privacy Overview',
        description: `Verkkokauppamme käyttää evästeitä käyttäjäkokemuksen parantamiseksi,
        analyysitiedon keräämiseksi ja markkinoinnin kohdentamiseksi sekä
        tällä sivustolla että kolmansien osapuolien palveluissa. Kaikki
        käyttämämme evästeet tallentuvat käyttämääsi selaimeen. Voit estää
        ei-tarvittavat evästeet.`,
        necessary: {
          header: {
            leftHeader: 'Välttämätön',
            rightHeader: 'Aina käytössä'
          },
          content: `Tarvittavat evästeet ovat välttämättömiä tämän verkkokaupan toiminnan kannalta. 
          Tarvittavat evästeet eivät kerää henkilötietoja.`
        },
        notNecessary: {
          header: {
            leftHeader: 'Ei-välttämätön',
            rightHeader: { onText: 'Käytössä', offText: 'Ei käytössä' }
          },
          content: `Käytämme verkkokaupassamme evästeitä, jotka keräävät käyttäjistä tietoa analysointia 
          ja kehittämistä varten, sekä evästeitä, joiden avulla kohdennamme markkinointia. Osa ei-välttämättömistä evästeistä 
          on kolmansien osapuolien evästeitä, jotka lähettävät tietoa näille kolmansille osapuolille.`
        }
      }
    }
  }
};
