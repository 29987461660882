import tiketti from './baseApi';

export const eventListSkeleton = {
  id: 0,
  eventID: '',
  name: '',
  event_start_date: '',
  event_end_date: '',
  locationID: '',
  location_name: '',
  organizerID: '',
  organizer_name: '',
  live_stream_id: null,
  shoutbox_id: '',
  eventLink: null,
  last_ticket_update: '',
  createdAt: '',
  updatedAt: ''
};

const defaults = {
  url: process.env.REACT_APP_API_URL_EVENTS
};

export const getAllEvents = async (
  locale,
  etype = null,
  page = 0,
  pageSize = null
) => {
  try {
    const url = `${defaults.url}/events`;
    const params = { locale, page, pageSize, etype };

    console.log('params', params, url);

    const response = await tiketti.get(url, params);
    console.log('response', response);
    return response;
  } catch (error) {
    console.log('error', error);
    return [eventListSkeleton];
  }
};

export const getSpecificEvent = async (eventID, locale) => {
  try {
    const url = `${defaults.url}/events/${eventID}`;
    const params = { locale };

    return await tiketti.get(url, params);
  } catch (error) {
    return [eventListSkeleton];
  }
};

export const searchEvents = async (q) => {
  try {
    const url = `${defaults.url}/events/search`;
    const params = { q };

    return await tiketti.get(url, params);
  } catch (error) {
    return false;
  }
};
