import { getAllEvents } from 'apis/events';
import { MAX_EVENT_REQUEST_LIMIT } from 'constants/event';
import { DEFAULT_LANG } from 'constants/translations';

export const retrieveInitialEvents = async (etype, language = DEFAULT_LANG) => {
  try {
    const events = await getAllEvents(
      language,
      etype,
      0,
      MAX_EVENT_REQUEST_LIMIT
    );

    const data = events.data;

    return { rows: data.rows, count: data.count };
  } catch {
    console.log('Failed to retrieve events');
  }
};
