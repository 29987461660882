import { useState, useCallback } from 'react';

import { retrieveTextContent } from 'apis/contentful';

const useTranslation = () => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const getTranslation = useCallback(async (lang) => {
    try {
      setLoading(true);
      const cTranslations = await retrieveTextContent(lang);
      setLoading(false);
      return cTranslations;
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  }, []);

  return { getTranslation, error, loading };
};

export default useTranslation;
